<template>
  <JenisVariableKpiForm mode="Tambah" module="Jenis Variable KPI"> </JenisVariableKpiForm>
</template>

<script>
import JenisVariableKpiForm from './form';

const JenisVariableKpiAdd = {
  name: 'JenisVariableKpiAdd',
  components: { JenisVariableKpiForm },
};

export default JenisVariableKpiAdd;
</script>
